import * as React from 'react';
import SiteButton from '../../SiteButton';
import { SkinButtonProps } from '../SkinButton';
import { ISiteButtonProps } from '../../../SiteButton.types';
import BaseButtonSkin from './BaseButton';
import skinsStyle from './styles/CircleButton.scss';

const CircleButtonSkin: React.FC<SkinButtonProps> = props => (
  <BaseButtonSkin {...props} skinsStyle={skinsStyle}></BaseButtonSkin>
);

const CircleButton: React.FC<Omit<ISiteButtonProps, 'skin'>> = props => (
  <SiteButton {...props} skin={CircleButtonSkin} />
);

export default CircleButton;
